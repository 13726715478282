import React, { FC, useContext } from "react";
import { graphql } from "gatsby";
import { PageQuery, BlockFragment } from "../../graphql-types";
import { Blocks } from "../components/blocks";
import { colors, spaceRem, targetBaseFontSizePx } from "../global-style";
import { LayoutContext } from "../components/layout";
import { css } from "styled-components";
import BackgroundImage, { IFluidObject } from "gatsby-background-image";

export const query = graphql`
  query Page($id: String) {
    header: file(relativePath: { eq: "page-template-header.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    wpPage(id: { eq: $id }) {
      title
      blocks {
        ...Block
      }
      blocksCustomFields {
        blocksAreAllWide
      }
    }
  }
`;

const Page: FC<{ data: PageQuery }> = props => {
  const { remInPx } = useContext(LayoutContext);

  return (
    <>
      <BackgroundImage
        style={{
          position: "relative",
          height: "30vw",
          minHeight: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        fluid={props.data.header?.childImageSharp?.fluid as IFluidObject}
      >
        <h1
          style={{
            hyphens: "none",
            maxWidth: "30ch",
            textAlign: "center",
            color: colors.white,
            paddingLeft: `${0.5 * spaceRem}rem`,
            paddingRight: `${0.5 * spaceRem}rem`,
          }}
          css={(() => {
            const minFontSizeRem = 1.25;
            const maxFontSizeRem = 3;
            const vwRangeStartPx = 500;

            return css`
              font-size: ${(minFontSizeRem *
                (remInPx || targetBaseFontSizePx)) /
                (vwRangeStartPx / 100)}vw;

              @media (max-width: ${vwRangeStartPx}px) {
                font-size: ${minFontSizeRem}rem;
              }

              @media (min-width: ${vwRangeStartPx *
                  (maxFontSizeRem / minFontSizeRem)}px) {
                font-size: ${maxFontSizeRem}rem;
              }
            `;
          })()}
          dangerouslySetInnerHTML={{
            __html: props.data.wpPage?.title || "",
          }}
        />

        <svg
          viewBox="0 0 1918 128"
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        >
          <defs>
            <path id="page_template_header_wave__a" d="M0 0h1918v325H0z" />
            <path id="page_template_header_wave__c" d="M0 0h1918v432H0z" />
          </defs>
          <g transform="translate(0 -26)" fill="none" fillRule="evenodd">
            <mask id="page_template_header_wave__b" fill={colors.white}>
              <use xlinkHref="#page_template_header_wave__a" />
            </mask>
            <g mask="url(#page_template_header_wave__b)">
              <g transform="rotate(180 959 231)">
                <mask id="page_template_header_wave__d" fill={colors.white}>
                  <use xlinkHref="#page_template_header_wave__c" />
                </mask>
                <g mask="url(#page_template_header_wave__d)">
                  <path
                    d="M1938.649 336.733C1282.579 230.378 647.83 504.607-8.004 402.473L-41.777-6.066C614.295 100.29 1249.044-173.94 1904.876-71.81l33.773 408.542z"
                    fill={colors.white}
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </BackgroundImage>

      <div>
        <Blocks
          blocks={props.data.wpPage?.blocks as BlockFragment[]}
          allWide={
            props.data.wpPage?.blocksCustomFields?.blocksAreAllWide || false
          }
        />
      </div>

      {/* <pre>{JSON.stringify(props.data, null, 2)}</pre> */}
    </>
  );
};

export default Page;
